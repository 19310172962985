/** @jsx jsx */
import { jsx } from "theme-ui";

const { wpPages } = require("../../data/wp-pages.json");

const ClassicMigration = () => {
  const total = wpPages.length;
  const redirects = wpPages.filter(
    (r) => "redirect" in r && r.redirect === true
  );
  // const stale = total - redirects.length;
  const contentRedirects = wpPages.filter(
    (r) => "firebase-redirect" in r && r["firebase-redirect"]
  );
  const localRedirects = wpPages.filter(
    (r) => "local-redirect" in r && r["local-redirect"]
  );
  const migratedRedirects = wpPages.filter(
    (r) => "redirect" in r && !r["firebase-redirect"] && r.redirect === false
  );

  const disabled = wpPages.filter((r) => "disable" in r);

  return (
    <div sx={{ p: 2 }}>
      <h1> Classic Migration Status</h1>
      <table>
        <tbody>
          <tr>
            <th sx={{ p: 1 }}> Total </th>
            <td sx={{ textAlign: "center" }}> {total} </td>
          </tr>
          {/* <tr>
            <th sx={{ p: 1 }}> Stale/Inactive Links (ignored) </th>
            <td sx={{ textAlign: "center" }}> {stale} </td>
          </tr> */}
          <tr>
            <th sx={{ p: 1 }}> Local redirects to Gatsby pages </th>
            <td sx={{ textAlign: "center" }}> {localRedirects.length} </td>
          </tr>
          <tr sx={{ bg: "beige" }}>
            <th sx={{ p: 1 }}> Static Content / API level redirects </th>
            <td sx={{ textAlign: "center" }}>
              {contentRedirects.length} <div> Target 0 </div>
            </td>
          </tr>
          <tr>
            <th sx={{ p: 1 }}> Disabled Page </th>
            <td sx={{ textAlign: "center", px: 3 }}>{disabled.length}</td>
          </tr>
          <tr sx={{ bg: "orange" }}>
            <th sx={{ p: 1 }}> Active WordPress Page Redirects (to Migrate)</th>
            <td sx={{ textAlign: "center", px: 3 }}>
              {redirects.length}
              <div sx={{ fontSize: "1.2rem", color: "blue" }}>Target 0</div>
            </td>
          </tr>
          <tr sx={{ bg: "green" }}>
            <th sx={{ p: 1 }}> Migrated Page </th>
            <td sx={{ textAlign: "center", px: 3 }}>
              {migratedRedirects.length}
            </td>
          </tr>
        </tbody>
      </table>

      <div sx={{ my: 4 }}>
        <h4> (P0) Disabled Page </h4>
        <ol>
          {disabled.map((r) => (
            <li key={r.path}>
              <a href={r.path} target="_blank" rel="noreferrer">
                {r.path}
              </a>
            </li>
          ))}
        </ol>
      </div>
      <div sx={{ my: 4 }}>
        <h4> (P1) Active WordPress Page Redirects </h4>
        <ol>
          {redirects.map((r) => (
            <li key={r.path}>
              <a href={r.path} target="_blank" rel="noreferrer">
                {r.path}
              </a>
            </li>
          ))}
        </ol>
      </div>
      <div sx={{ my: 4 }}>
        <h4> (P2) Static Content / API level redirects </h4>
        <ol>
          {contentRedirects.map((r) => (
            <li key={r.path}>{r.path}</li>
          ))}
        </ol>
      </div>

      <div sx={{ my: 4 }}>
        <h4> (P3) Migrated Pages </h4>
        <ol>
          {migratedRedirects.map((r) => (
            <li key={r.path}>
              <a href={r.path} target="_blank" rel="noreferrer">
                {r.path}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ClassicMigration;
